var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"pa-0 ma-0"},[_c('div',{staticClass:"col-md-12 pa-0 ma-0"},_vm._l((_vm.categories),function(category){return _c('div',{key:category.id,staticClass:"mb-15"},[_c('div',{staticClass:"accent pa-2"},[_c('h2',{staticClass:"m y-2"},[_vm._v(_vm._s(category.name))]),_c('v-slide-group',{staticClass:"absolute-icons",attrs:{"active-class":"success","show-arrows":""},scopedSlots:_vm._u([{key:"next",fn:function(){return [_c('v-icon',{staticClass:"primary white--text slider-icon slide-icon__right",attrs:{"large":""}},[_vm._v("mdi-chevron-right")])]},proxy:true},{key:"prev",fn:function(){return [_c('v-icon',{staticClass:"primary white--text slider-icon slide-icon__left",attrs:{"large":""}},[_vm._v("mdi-chevron-left")])]},proxy:true}],null,true)},[_c('v-container',{staticClass:"d-flex mt-0",attrs:{"fluid":""}},_vm._l((category.subcategories),function(subCategory){return _c('v-card',{key:subCategory.id,staticClass:"me-15 d-flex flex-column align-center smooth",attrs:{"ripple":false,"tile":"","flat":""},on:{"click":function($event){return _vm.$router.push({
                      name: 'SubCategory',
                      params: {
                        branchId: _vm.branchId,
                        branchName: _vm.branchName,
                        subCategoryId: subCategory.id,
                        subCategoryName: subCategory.name,
                        categoryId: category.id,
                        categoryName: category.name,
                      },
                    })}}},[_c('v-img',{staticClass:"shrink ho",attrs:{"src":`${_vm.subcatImageUrl}/${subCategory.image}`,"width":"150","alt":subCategory.name + '_image'}}),_c('v-card-text',{staticClass:"mt-2 text-center black--text"},[_c('strong',[_vm._v(_vm._s(subCategory.name))])])],1)}),1)],1)],1)])}),0)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }